<!--
 * @Descripttion:
 * @version:
 * @Author: peter
 * @Date: 2021-02-01 10:57:47
 * @LastEditors: peter
 * @LastEditTime: 2021-10-26 11:52:12
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { getDictList } from './utils/api'
export default {
  name: 'App',
  components: {
  },
  async created() {
    const res = await getDictList()
    if (res.success) {
      sessionStorage.setItem('dictList', JSON.stringify(res.data))
    }
  }
}
</script>

<style>

</style>

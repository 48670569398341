/*
 * @Descripttion:
 * @version:
 * @Author: peter
 * @Date: 2021-09-13 09:34:21
 * @LastEditors: peter
 * @LastEditTime: 2021-09-22 09:50:12
 */
import axios from "axios";
import store from "@/store";
import router from "@/router/router";
// import Qs from 'qs'

const http = axios.create({
  timeout: 1000 * 30,
  method: "POST",
  // paramsSerializer: function(params) {
  //   return Qs.stringify(params, { arrayFormat: 'brackets' })
  // },
  headers: {
    // 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    "Content-Type": "application/json",
  },
});

function objToParams(obj) {
  var list = [];
  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      list.push(key + "=" + obj[key]);
    }
  }
  return list.join("&");
}

/**
 * 请求拦截
 */
http.interceptors.request.use(
  (config) => {
    console.log("store", store);
    config.headers = {
      ...config.headers,
      companyId: store.state.companyId,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * 响应拦截
 */
http.interceptors.response.use(
  (response) => {
    if (
      response.data &&
      (response.data.message === "no token" || response.data.code === 1525)
    ) {
      // 401, token失效
      router.push({ name: "login" });
    }
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
http.api = (actionName) => {
  // 非生产环境 && 开启代理, 接口前缀统一使用[/proxyApi/]前缀做代理拦截!
  return (
    (window.SITE_CONFIG.hasMock ? "/proxyApi/" : window.SITE_CONFIG["api"]) +
    actionName
  );
};

/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefultdata 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
http.adornData = (data = {}, openDefultdata = true, contentType = "json") => {
  var defaults = {
    loginInfo: {
      auth: store.state.auth,
      companyId: store.state.companyId,
      phone: sessionStorage.account,
    },
  };
  data = openDefultdata ? Object.assign(data, defaults) : data;
  return contentType === "json" ? JSON.stringify(data) : objToParams(data);
};

export default http;

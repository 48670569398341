/*
 * @Descripttion:
 * @version:
 * @Author: peter
 * @Date: 2021-02-24 16:19:35
 * @LastEditors: peter
 * @LastEditTime: 2021-11-05 16:18:28
 */
import moment from "moment";

const filters = {
  // 格式化时间 pattern取值: YYYY-MM-DD / YYYY-MM-DD HH / YYYY-MM-DD HH:mm / YYYY-MM-DD HH:mm:ss
  F_formatDate: function(date, pattern) {
    // 年月日
    if (date) {
      if (pattern) {
        return moment(date).format(pattern);
      } else {
        return moment(date).format("YYYY-MM-DD");
      }
    } else {
      return "";
    }
  },
  F_formatPhone: function(value) {
    if (value) {
      const showPhone = value.replace(/^(\d{3})\d{4}(\d+)/, "$1****$2");
      return showPhone;
    }
    return value;
  },
};

export default {
  install: function(Vue, Option) {
    for (const key in filters) {
      Vue.filter(key, filters[key]);
    }
  },
};

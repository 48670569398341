import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/login',
      name: 'login',
      meta: { title: '登录' },
      component: () => import('@/views/login/login')
    },
    {
      path: '/',
      name: 'main',
      meta: { title: '主页' },
      redirect: { name: 'index' },
      component: () => import('@/views/main/main'),
      children: [
        {
          path: '/index',
          name: 'index',
          meta: { title: '首页' },
          component: () => import('@/views/index/index')
        },
        {
          path: '/veteran',
          name: 'veteran',
          meta: { title: '老兵管理' },
          redirect: { name: 'veteranManage' },
          component: () => import('@/views/veteran/index'),
          children: [
            {
              path: 'veteranManage',
              name: 'veteranManage',
              meta: { title: '老兵管理' },
              component: () => import('@/views/veteran/veteranManage')
            },
            {
              path: 'veteranClue',
              name: 'veteranClue',
              meta: { title: '老兵线索' },
              component: () => import('@/views/veteran/veteranClue')
            },
            {
              path: 'hero',
              name: 'hero',
              meta: { title: '英烈墙' },
              component: () => import('@/views/veteran/hero')
            }
          ]
        },
        {
          path: '/medalManage',
          name: 'medalManage',
          meta: { title: '勋章管理' },
          component: () => import('@/views/medal/index')
        },
        {
          path: '/money-collecting',
          name: 'moneyCollecting',
          meta: { title: '老兵筹' },
          redirect: { name: 'examine' },
          component: () => import('@/views/money-collecting/index'),
          children: [
            {
              path: 'examine',
              name: 'examine',
              meta: { title: '求助审核' },
              component: () => import('@/views/money-collecting/examine')
            },
            {
              path: 'manage',
              name: 'manage',
              meta: { title: '求助管理' },
              component: () => import('@/views/money-collecting/manage')
            },
            {
              path: 'identity',
              name: 'identity',
              meta: { title: '军人身份认证' },
              component: () => import('@/views/money-collecting/identity')
            },
            {
              path: 'reply',
              name: 'reply',
              meta: { title: '捐赠回复管理' },
              component: () => import('@/views/money-collecting/reply')
            },
            {
              path: 'donationRecord',
              name: 'donationRecord',
              meta: { title: '捐赠记录' },
              component: () =>
                import('@/views/money-collecting/donation-record')
            },
            {
              path: 'process',
              name: 'process',
              meta: { title: '进展审核' },
              component: () => import('@/views/money-collecting/process')
            },
            {
              path: 'payManage',
              name: 'payManage',
              meta: { title: '支付管理' },
              component: () => import('@/views/money-collecting/payManage')
            }
          ]
        },
        {
          path: '/carousel',
          name: 'carousel',
          meta: { title: '轮播资讯' },
          redirect: { name: 'carouselManage' },
          component: () => import('@/views/carousel/index'),
          children: [
            {
              path: 'carouselManage',
              name: 'carouselManage',
              meta: { title: '轮播图管理' },
              component: () => import('@/views/carousel/carouselManage')
            },
            {
              path: 'infoManage',
              name: 'infoManage',
              meta: { title: '资讯管理' },
              component: () => import('@/views/carousel/infoManage')
            }
          ]
        },
        {
          path: '/userManage',
          name: 'userManage',
          meta: { title: '用户管理' },
          component: () => import('@/views/user/index')
        },
        {
          path: '/adminManage',
          name: 'adminManage',
          meta: { title: '管理员管理' },
          component: () => import('@/views/admin/index')
        }
      ]
    },
    {
      path: '*',
      redirect: { name: 'index' }
    }
  ]
})

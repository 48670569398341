import router from './router/router.js'

router.beforeEach((to, from, next) => {
  if (!sessionStorage.auth && to.name !== 'login') {
    next({
      name: 'login'
    })
  } else {
    next()
  }
})

export default router

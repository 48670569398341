import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store'
import './plugins/element.js'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import filters from '@/utils/filter.js'
import onlyNumber from '@/directive/el-input' // 添加此行=>自定义全局指令
import httpRequest from './utils/httpRequest'

Vue.config.productionTip = false
Vue.use(onlyNumber)
Vue.use(ElementUI)

Vue.use(filters)
Vue.prototype.$http = httpRequest
import '@/assets/style/index.less'

import './permission'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

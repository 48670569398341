/*
 * @Descripttion:
 * @version:
 * @Author: peter
 * @Date: 2021-09-13 09:34:21
 * @LastEditors: peter
 * @LastEditTime: 2021-09-22 09:49:51
 */
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
  nickname: sessionStorage.nickname ? sessionStorage.nickname : "",
  auth: sessionStorage.auth ? sessionStorage.auth : "",
  userId: sessionStorage.userId ? sessionStorage.userId : "",
  companyId: sessionStorage.companyId ? sessionStorage.companyId : "",
};

const mutations = {
  saveInfo(state, data) {
    state.nickname = data.nickname;
    state.auth = data.auth;
    state.userId = data.userId;
    state.companyId = data.curCompanyId;
    sessionStorage.companyId = data.curCompanyId;
    const firstCompany = data.companyList[0];
    if (!data.selectCompany && firstCompany && firstCompany.id) {
      state.companyId = firstCompany.id;
      sessionStorage.companyId = firstCompany.id;
    }
    sessionStorage.nickname = data.nickname;
    sessionStorage.auth = data.auth;
    sessionStorage.userId = data.userId;
  },
  saveCompanyId(state, id) {
    state.companyId = id;
    sessionStorage.companyId = id;
  },
};

export default new Vuex.Store({
  state,
  mutations,
  actions: {},
  modules: {},
});

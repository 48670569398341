import httpRequest from './httpRequest'

// 字典获取
export const getDictList = () => {
  return httpRequest({
    url: httpRequest.api('/veteran/dict/getDictList'),
    method: 'post'
  })
}

// 登录
export const webLogin = params => {
  return httpRequest({
    url: httpRequest.api('/webLogin'),
    method: 'post',
    data: httpRequest.adornData(params, false)
  })
}

// 管理员选择公司
export const chooseCompany = params => {
  return httpRequest({
    url: httpRequest.api('/chooseCompany'),
    method: 'post',
    data: httpRequest.adornData(params, false)
  })
}

/** 老兵管理 */
// 查询老兵信息列表
export const veteranInfoList = (params, current, size) => {
  return httpRequest({
    url: httpRequest.api('/veteranInfo/list'),
    method: 'post',
    data: httpRequest.adornData(params),
    params: { current, size }
  })
}

// 添加老兵信息
export const addVeteranInfo = params => {
  return httpRequest({
    url: httpRequest.api('/veteranInfo/add'),
    method: 'post',
    data: httpRequest.adornData(params)
  })
}

// 老兵详情
export const veteranDetail = (params, id) => {
  return httpRequest({
    url: httpRequest.api(`/veteranInfo/detail/${id}`),
    method: 'post',
    data: httpRequest.adornData(params)
  })
}

// 修改老兵信息
export const updateVeteranInfo = params => {
  return httpRequest({
    url: httpRequest.api('/veteranInfo/update'),
    method: 'post',
    data: httpRequest.adornData(params)
  })
}

// 删除老兵信息
export const deleteVeteran = (params, id) => {
  return httpRequest({
    url: httpRequest.api(`/veteranInfo/delete/${id}`),
    method: 'post',
    data: httpRequest.adornData(params)
  })
}

// 批量删除
export const deleteAllVeteran = params => {
  return httpRequest({
    url: httpRequest.api(`veteranInfo/delete/list`),
    method: 'post',
    data: httpRequest.adornData(params)
  })
}

// 老兵线索列表
export const veteranClueList = (params, current, size) => {
  return httpRequest({
    url: httpRequest.api('/veteranClue/list'),
    method: 'post',
    data: httpRequest.adornData(params),
    params: { current, size }
  })
}

// 老兵线索详情
export const veteranClueInfo = id => {
  return httpRequest({
    url: httpRequest.api(`/veteranClue/info/${id}`),
    method: 'get'
  })
}

// 删除（即已处理）老兵线索
export const deleteVeteranClue = id => {
  return httpRequest({
    url: httpRequest.api(`/veteranClue/delete/${id}`),
    method: 'post'
  })
}

/** 英烈墙 */
// 英烈墙列表
export const veteranWallList = params => {
  return httpRequest({
    url: httpRequest.api('/veteranWall/list'),
    method: 'post',
    data: httpRequest.adornData(params)
  })
}

// 重新随机
export const randomVeteranWall = params => {
  return httpRequest({
    url: httpRequest.api('/veteranWall/recreate'),
    method: 'post',
    data: httpRequest.adornData(params, false)
  })
}

// 英烈墙设置信息
export const getVeteranWallSetting = params => {
  return httpRequest({
    url: httpRequest.api('/veteranWallSetting/detail'),
    method: 'post',
    data: httpRequest.adornData(params)
  })
}

// 英烈墙设置
export const setVetaranWall = params => {
  return httpRequest({
    url: httpRequest.api('/veteranWallSetting/add'),
    method: 'post',
    data: httpRequest.adornData(params)
  })
}

// 根据日期查询英烈墙数据
export const listByDate = params => {
  return httpRequest({
    url: httpRequest.api('/veteranWall/listByDate'),
    method: 'post',
    data: httpRequest.adornData(params)
  })
}

// 批量调节
export const updateByDate = params => {
  return httpRequest({
    url: httpRequest.api('/veteranWall/updateByDate'),
    method: 'post',
    data: httpRequest.adornData(params)
  })
}

/** 勋章管理 */
// 勋章列表
export const medalDataList = (params, current, size) => {
  return httpRequest({
    url: httpRequest.api('/medal/list'),
    method: 'post',
    data: httpRequest.adornData(params),
    params: { current, size }
  })
}

// 添加勋章
export const addMedal = params => {
  return httpRequest({
    url: httpRequest.api('/medal/add'),
    method: 'post',
    data: httpRequest.adornData(params)
  })
}

// 修改勋章
export const updateMedal = params => {
  return httpRequest({
    url: httpRequest.api('/medal/update'),
    method: 'post',
    data: httpRequest.adornData(params)
  })
}

// 删除订单
export const deleteMedal = (params, id) => {
  return httpRequest({
    url: httpRequest.api(`/medal/delete/${id}`),
    method: 'post',
    data: httpRequest.adornData(params)
  })
}

/** 轮播图 */
// 轮播图列表
export const slideShowList = (params, current, size) => {
  return httpRequest({
    url: httpRequest.api('/slideshow/list'),
    method: 'post',
    data: httpRequest.adornData(params),
    params: { current, size }
  })
}

// 添加轮播图
export const addSlideShow = params => {
  return httpRequest({
    url: httpRequest.api('/slideshow/add'),
    method: 'post',
    data: httpRequest.adornData(params)
  })
}

// 修改轮播图
export const updateSlideShow = params => {
  return httpRequest({
    url: httpRequest.api('/slideshow/update'),
    method: 'post',
    data: httpRequest.adornData(params)
  })
}

// 删除轮播图
export const deleteSlideShow = id => {
  return httpRequest({
    url: httpRequest.api(`/slideshow/delete/${id}`),
    method: 'post'
  })
}

/** 咨讯 */
// 咨讯列表
export const getNewsList = (params, current, size) => {
  return httpRequest({
    url: httpRequest.api('/news/list'),
    method: 'post',
    data: httpRequest.adornData(params),
    params: { current, size }
  })
}

// 获取资讯详情
export const getNewsDetail = id => {
  return httpRequest({
    url: httpRequest.api(`/news/select/${id}`),
    method: 'get'
  })
}

// 新增咨讯
export const addNews = params => {
  return httpRequest({
    url: httpRequest.api('/news/add'),
    method: 'post',
    data: httpRequest.adornData(params)
  })
}

// 修改咨讯
export const updateNews = params => {
  return httpRequest({
    url: httpRequest.api('/news/update'),
    method: 'post',
    data: httpRequest.adornData(params)
  })
}

// 删除咨讯
export const deleteNews = id => {
  return httpRequest({
    url: httpRequest.api(`/news/delete/${id}`),
    method: 'post'
  })
}

/** 用户管理 */
// 用户列表
export const userInfoList = (params, current, size) => {
  return httpRequest({
    url: httpRequest.api('/userInfo/list'),
    method: 'post',
    data: httpRequest.adornData(params),
    params: { current, size }
  })
}

/** 管理员管理 */
// 管理员列表
export const getAdminAuthorityList = (params, current, size) => {
  return httpRequest({
    url: httpRequest.api('/adminAuthority/list'),
    method: 'post',
    data: httpRequest.adornData(params),
    params: { current, size }
  })
}

// 权限列表
export const getAuthorityList = id => {
  return httpRequest({
    url: httpRequest.api(`/authority/list`),
    method: 'get'
  })
}

// 设置权限
export const setAdminAuthority = params => {
  return httpRequest({
    url: httpRequest.api('/adminAuthority/set'),
    method: 'post',
    data: httpRequest.adornData(params)
  })
}

// 导出用户
export const exportUser = (data = {}) => {
  return httpRequest({
    url: httpRequest.api('/userInfo/exportUser'),
    method: 'post',
    data: httpRequest.adornData(data)
  })
}

// // 获取管理员权限
// export const getAdminAuthorityList = (params) => {
//   return httpRequest({
//     url: httpRequest.api(`/adminAuthority/list`),
//     method: 'post',
//     data: httpRequest.adornData(params)
//   })
// }

// 老兵模板下载
export const downModel = () => {
  return httpRequest({
    url: httpRequest.api(`/veteranInfo/downModel`),
    method: 'get'
  })
}

// 首页统计
export const getIndexInfo = params => {
  return httpRequest({
    url: httpRequest.api(`/statistics/indexInfo`),
    method: 'post',
    data: httpRequest.adornData(params)
  })
}

// 上传文件
export const uploadFile = formDate => {
  return httpRequest({
    url: httpRequest.api('/file/upload'),
    method: 'post',
    data: formDate,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

// 获取筹款项目列表
export const getCollectingList = params => {
  return httpRequest({
    url: httpRequest.api(`/lbc/money/collecting/listPage`),
    method: 'get',
    params
  })
}

// 获取筹款详情
export const getCollectingDetail = data => {
  return httpRequest({
    url: httpRequest.api('/lbc/money/collecting/view'),
    method: 'post',
    data: httpRequest.adornData(data)
  })
}

// 修改筹款详情
export const editCollectingDetail = data => {
  return httpRequest({
    url: httpRequest.api('/lbc/money/collecting/save'),
    method: 'post',
    data: httpRequest.adornData(data)
  })
}

// 获取军人认证列表
export const getSoldierAuth = params => {
  return httpRequest({
    url: httpRequest.api(`/lbc/cert/info/listPage`),
    method: 'get',
    params
  })
}

// 删除军人认证
export const deleteSoliderAuth = id => {
  return httpRequest({
    url: httpRequest.api(`/lbc/cert/info/remove/${id}`),
    method: 'get'
  })
}

// 获取军人详情
export const getSoldierDetail = id => {
  return httpRequest({
    url: httpRequest.api(`/lbc/cert/info/view/${id}`),
    method: 'get'
  })
}

// 修改军人认证详情
export const editSoldierDetail = data => {
  return httpRequest({
    url: httpRequest.api('/lbc/cert/info/save'),
    method: 'post',
    data: httpRequest.adornData(data)
  })
}

// 获取捐赠记录
export const getDonationRecord = params => {
  return httpRequest({
    url: httpRequest.api(`/lbc/contribution/record/listPage`),
    method: 'get',
    params
  })
}

// 更新捐赠详情
export const updateContribution = data => {
  return httpRequest({
    url: httpRequest.api(`/lbc/contribution/record/updateContribution`),
    method: 'post',
    data: httpRequest.adornData(data)
  })
}

// 获取支付配置信息
export const getPayOptions = (data = {}) => {
  return httpRequest({
    url: httpRequest.api(`/lbc/wx/view`),
    method: 'post',
    data: httpRequest.adornData(data)
  })
}

// 保存支付配置
export const savePayOptions = data => {
  return httpRequest({
    url: httpRequest.api(`/lbc/wx/saveOrUpdate`),
    method: 'post',
    data: httpRequest.adornData(data)
  })
}

// 获取项目进展
export const getProjectProcess = data => {
  return httpRequest({
    url: httpRequest.api(`/lbc/project/progress/getProgressList`),
    method: 'post',
    data: httpRequest.adornData(data)
  })
}

// 设置筹款等级
export const setOrderLevel = data => {
  return httpRequest({
    url: httpRequest.api(`/lbc/money/collecting/setOrderLevel`),
    method: 'post',
    data: httpRequest.adornData(data)
  })
}

// 设置筹款等级
export const cancelOrderLevel = data => {
  return httpRequest({
    url: httpRequest.api(`/lbc/money/collecting/cancelOrderLevel`),
    method: 'post',
    data: httpRequest.adornData(data)
  })
}

// 获取进展列表
export const getProgressPage = params => {
  return httpRequest({
    url: httpRequest.api(`/lbc/project/progress/getProgressPage`),
    method: 'get',
    params
  })
}

// 获取进展详情
export const getProgressDetail = id => {
  return httpRequest({
    url: httpRequest.api(`/lbc/project/progress/view/${id}`),
    method: 'post'
  })
}

// 审核进展
export const checkProjectProgress = data => {
  return httpRequest({
    url: httpRequest.api(`/lbc/project/progress/checkProjectProgress`),
    method: 'post',
    data: httpRequest.adornData(data)
  })
}

// 删除进展
export const removeProgress = id => {
  return httpRequest({
    url: httpRequest.api(`/lbc/project/progress/remove/${id}`),
    method: 'post'
  })
}

// 添加进展
export const addProgress = data => {
  return httpRequest({
    url: httpRequest.api(`/lbc/project/progress/addProjectProgress`),
    method: 'post',
    data: httpRequest.adornData(data)
  })
}

// 用户勋章列表
export const getUserMedals = data => {
  return httpRequest({
    url: httpRequest.api(`/userMedal/getUserMedalList`),
    method: 'post',
    data: httpRequest.adornData(data)
  })
}
